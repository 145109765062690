import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Live_Video/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "QuickTime Video Plugin",
  "path": "/Quick_Installation/Live_Video/QuickTime/",
  "dateChanged": "2017-11-23",
  "author": "Mike Polinowski",
  "excerpt": "Use the QuickTime Video Plugin to view your Camera's Live Video",
  "image": "./QI_SearchThumb_LiveVideo_QuickTime.webp",
  "social": "/images/Search/QI_SearchThumb_LiveVideo.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Live_Video_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='QuickTime Video Plugin' dateChanged='2017-11-23' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the QuickTime Video Plugin to view your Camera`s Live Video' image='/images/Search/QI_SearchThumb_LiveVideo.png' twitter='/images/Search/QI_SearchThumb_LiveVideo.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Live_Video/QuickTime/' locationFR='/fr/Quick_Installation/Live_Video/QuickTime/' crumbLabel="QuickTime" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "quicktime-plugin-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#quicktime-plugin-setup",
        "aria-label": "quicktime plugin setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Quicktime Plugin Setup`}</h2>
    <p>{`The Quicktime Plugin is needed to display the h.264 stream of your INSTAR HD camera in alternative Browsers like Chrome, Safari, Firefox or Opera. Please refer to `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/Live_Video/ActiveX/"
      }}>{`ActiveX Plugin Installation on Windows`}</a>{` if you want to use the Internet Explorer to access your camera.`}</p>
    <h3 {...{
      "id": "quicktime-setup-under-windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#quicktime-setup-under-windows",
        "aria-label": "quicktime setup under windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Quicktime Setup under Windows`}</h3>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Please download the newest version of Quicktime Player from `}<a href="http://www.apple.com/quicktime/download/" target="_blank" rel="noopener noreferrer">{`Apple Inc.`}</a>{` ...`}</p>
    <p>{`Download the newest version of the full Quicktime player.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b9e80677967e827c042360867749daf9/29d31/Win_Quicktime_Step1_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAADBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHtFUFMoVX/xAAZEAEBAAMBAAAAAAAAAAAAAAADAQIEESH/2gAIAQEAAQUC97ttQPFmyl73eOoUJ8Z//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAwACAwAAAAAAAAAAAAAAAAERAkEDMXH/2gAIAQEABj8C0LJK1lWCnpoxSnZFyKH/xAAbEAEAAgIDAAAAAAAAAAAAAAABACERYTFBcf/aAAgBAQABPyGtTGCKocwa+p6Zhtj5hM78gQQbZ//aAAwDAQACAAMAAAAQbB//xAAXEQEAAwAAAAAAAAAAAAAAAAAAASEx/9oACAEDAQE/EE7T/8QAFREBAQAAAAAAAAAAAAAAAAAAEQD/2gAIAQIBAT8QCb//xAAdEAEBAAICAwEAAAAAAAAAAAABEQAhMVFBobHB/9oACAEBAAE/EERJfW0xgJWMeF/M42Mut3OsZd+m0vzLAgLQ5dGHWyAFPWf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9e80677967e827c042360867749daf9/e4706/Win_Quicktime_Step1_EN.avif 230w", "/en/static/b9e80677967e827c042360867749daf9/d1af7/Win_Quicktime_Step1_EN.avif 460w", "/en/static/b9e80677967e827c042360867749daf9/70e80/Win_Quicktime_Step1_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b9e80677967e827c042360867749daf9/a0b58/Win_Quicktime_Step1_EN.webp 230w", "/en/static/b9e80677967e827c042360867749daf9/bc10c/Win_Quicktime_Step1_EN.webp 460w", "/en/static/b9e80677967e827c042360867749daf9/426ac/Win_Quicktime_Step1_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9e80677967e827c042360867749daf9/e83b4/Win_Quicktime_Step1_EN.jpg 230w", "/en/static/b9e80677967e827c042360867749daf9/e41a8/Win_Quicktime_Step1_EN.jpg 460w", "/en/static/b9e80677967e827c042360867749daf9/29d31/Win_Quicktime_Step1_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b9e80677967e827c042360867749daf9/29d31/Win_Quicktime_Step1_EN.jpg",
              "alt": "QuickTime Download",
              "title": "QuickTime Download",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: Please click Run in order to download and start the installation of the Quicktime Player.`}</p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: Confirm that you want to install the ClientOCX_Setup.exe ActiveX Control Element by clicking Run in the upcoming window.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/41099/ActiveX_HD_02_Windows_XP_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHobUkVU//EABkQAQADAQEAAAAAAAAAAAAAAAABERMSQf/aAAgBAQABBQLhnbOnkJf/xAAWEQADAAAAAAAAAAAAAAAAAAABEBL/2gAIAQMBAT8BkL//xAAWEQADAAAAAAAAAAAAAAAAAAABEBL/2gAIAQIBAT8Bsr//xAAYEAADAQEAAAAAAAAAAAAAAAAAATEyEP/aAAgBAQAGPwLTNMpO/wD/xAAeEAEAAQMFAQAAAAAAAAAAAAABABEhMUGBkaGx4f/aAAgBAQABPyHGvQQ+WRwVuxK2HqLQHmO8/9oADAMBAAIAAwAAABAzL//EABYRAQEBAAAAAAAAAAAAAAAAAAAhAf/aAAgBAwEBPxAZI//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QnV63/8QAHhABAAICAQUAAAAAAAAAAAAAAQARMUHRIVFxofD/2gAIAQEAAT8QtRAfGo4urxwQK92t4IvrCJkhD2UgC7xtuf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/e4706/ActiveX_HD_02_Windows_XP_EN.avif 230w", "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/d1af7/ActiveX_HD_02_Windows_XP_EN.avif 460w", "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/df5cf/ActiveX_HD_02_Windows_XP_EN.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/a0b58/ActiveX_HD_02_Windows_XP_EN.webp 230w", "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/bc10c/ActiveX_HD_02_Windows_XP_EN.webp 460w", "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/b0a15/ActiveX_HD_02_Windows_XP_EN.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/e83b4/ActiveX_HD_02_Windows_XP_EN.jpg 230w", "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/e41a8/ActiveX_HD_02_Windows_XP_EN.jpg 460w", "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/41099/ActiveX_HD_02_Windows_XP_EN.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/799c7fb96e8aa0d9d279ef5f9f17ab5d/41099/ActiveX_HD_02_Windows_XP_EN.jpg",
              "alt": "QuickTime Video Plugin",
              "title": "QuickTime Video Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 4`}</strong>{`: After the installation please double click on QuickTime Player to start the program.`}</p>
    <p><strong parentName="p">{`Step 5`}</strong>{`: Please select Edit - Preferences and the menu Quicktime Preferences.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/956102bcfaeb80f72276ae615bc39e39/29d31/Win_Quicktime_Step3_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQDBQb/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABts9MzCI8V//EABkQAAMBAQEAAAAAAAAAAAAAAAECAxIAE//aAAgBAQABBQJAMs1RTbd611OE3mZp3//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAIDAQEAAAAAAAAAAAAAAAABAhEhEjL/2gAIAQEABj8CRs3VntldNIU5KpGH/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARMSFBYdH/2gAIAQEAAT8hwBkVnITuC/WGDXdTmqCtNQzuvWf/2gAMAwEAAgADAAAAEBsP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QCf/EABoQAQEBAQEBAQAAAAAAAAAAAAERIQAxQZH/2gAIAQEAAT8QQ6zFZ7nGYsAAJqRnWNIzVyULgLJnnUROjHU8e+v24rfzv//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/956102bcfaeb80f72276ae615bc39e39/e4706/Win_Quicktime_Step3_EN.avif 230w", "/en/static/956102bcfaeb80f72276ae615bc39e39/d1af7/Win_Quicktime_Step3_EN.avif 460w", "/en/static/956102bcfaeb80f72276ae615bc39e39/70e80/Win_Quicktime_Step3_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/956102bcfaeb80f72276ae615bc39e39/a0b58/Win_Quicktime_Step3_EN.webp 230w", "/en/static/956102bcfaeb80f72276ae615bc39e39/bc10c/Win_Quicktime_Step3_EN.webp 460w", "/en/static/956102bcfaeb80f72276ae615bc39e39/426ac/Win_Quicktime_Step3_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/956102bcfaeb80f72276ae615bc39e39/e83b4/Win_Quicktime_Step3_EN.jpg 230w", "/en/static/956102bcfaeb80f72276ae615bc39e39/e41a8/Win_Quicktime_Step3_EN.jpg 460w", "/en/static/956102bcfaeb80f72276ae615bc39e39/29d31/Win_Quicktime_Step3_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/956102bcfaeb80f72276ae615bc39e39/29d31/Win_Quicktime_Step3_EN.jpg",
              "alt": "QuickTime Video Plugin",
              "title": "QuickTime Video Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Navigate to the Quicktime Preferences.`}</p>
    <p><strong parentName="p">{`Step 6`}</strong>{`: Please click on Advanced, you should now see the below window.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "416px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ec9a7000c391c070a9a359c529c52d27/8d0ef/Win_Quicktime_Step4_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "134.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQABAQADAQAAAAAAAAAAAAAAAAIBAwQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgEA/9oADAMBAAIQAxAAAAH1J6KR5W1rVYgUFP/EABsQAAICAwEAAAAAAAAAAAAAAAACARADEzER/9oACAEBAAEFAtSGlScawe03SBu0x//EABYRAAMAAAAAAAAAAAAAAAAAAAERIP/aAAgBAwEBPwFCP//EABYRAAMAAAAAAAAAAAAAAAAAAAESIP/aAAgBAgEBPwFjH//EABkQAAMAAwAAAAAAAAAAAAAAAAABMjAzkf/aAAgBAQAGPwKTWukrF//EAB0QAAICAgMBAAAAAAAAAAAAAAABETEhcRBBYaH/2gAIAQEAAT8hwYV8ChlAKBdMq0R58IdFWhUiWWP/2gAMAwEAAgADAAAAEEQbgf/EABoRAAAHAAAAAAAAAAAAAAAAAAABEBEhMUH/2gAIAQMBAT8QewVBL//EABkRAAIDAQAAAAAAAAAAAAAAAAABESExQf/aAAgBAgEBPxCPpLdmiw//xAAfEAEAAgIBBQEAAAAAAAAAAAABABEhQTFRcYHB8GH/2gAIAQEAAT8QMRaitSnn7dpRmLaDe+sBAmaNwQtviGgtep7sE0tijkPuYnDxPo/WJ5tELsO5me3uf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ec9a7000c391c070a9a359c529c52d27/e4706/Win_Quicktime_Step4_EN.avif 230w", "/en/static/ec9a7000c391c070a9a359c529c52d27/a0751/Win_Quicktime_Step4_EN.avif 416w"],
              "sizes": "(max-width: 416px) 100vw, 416px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ec9a7000c391c070a9a359c529c52d27/a0b58/Win_Quicktime_Step4_EN.webp 230w", "/en/static/ec9a7000c391c070a9a359c529c52d27/da4cb/Win_Quicktime_Step4_EN.webp 416w"],
              "sizes": "(max-width: 416px) 100vw, 416px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ec9a7000c391c070a9a359c529c52d27/e83b4/Win_Quicktime_Step4_EN.jpg 230w", "/en/static/ec9a7000c391c070a9a359c529c52d27/8d0ef/Win_Quicktime_Step4_EN.jpg 416w"],
              "sizes": "(max-width: 416px) 100vw, 416px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ec9a7000c391c070a9a359c529c52d27/8d0ef/Win_Quicktime_Step4_EN.jpg",
              "alt": "QuickTime Video Plugin",
              "title": "QuickTime Video Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the Advanced tab.`}</p>
    <p><strong parentName="p">{`Step 7`}</strong>{`: Please choose Custom... as the Streaming Transport and select Transport Protocol: HTTP and Port ID: 80 in the next window.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "416px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a546aa064a676c50b82a04473bee5218/8d0ef/Win_Quicktime_Step5_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "134.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIBAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAfXqOaPRrFWpMG3/xAAbEAACAgMBAAAAAAAAAAAAAAAAAQIQERITI//aAAgBAQABBQLRHOI4RHtn1vCHbP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABURAQEAAAAAAAAAAAAAAAAAACCB/9oACAECAQE/AaP/xAAbEAABBAMAAAAAAAAAAAAAAAAAARAhMSCRof/aAAgBAQAGPwKmoheFponP/8QAHBAAAwADAAMAAAAAAAAAAAAAAAERMUFhIdHw/9oACAEBAAE/IULgKPA0tLtD+z2eYqrIxMFjNFZkf//aAAwDAQACAAMAAAAQhMq8/8QAFxEBAAMAAAAAAAAAAAAAAAAAAREgQf/aAAgBAwEBPxBYyn//xAAYEQADAQEAAAAAAAAAAAAAAAAAAREh8f/aAAgBAgEBPxBb0Vloj//EAB4QAQEAAgICAwAAAAAAAAAAAAERACExcUFRseHw/9oACAEBAAE/EOD6kyPJwAHF3hdBwJR84r0I8MYvjhtku3LN3IfucJWC9/eAAEJgsb8YgkZvNx1n/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a546aa064a676c50b82a04473bee5218/e4706/Win_Quicktime_Step5_EN.avif 230w", "/en/static/a546aa064a676c50b82a04473bee5218/a0751/Win_Quicktime_Step5_EN.avif 416w"],
              "sizes": "(max-width: 416px) 100vw, 416px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a546aa064a676c50b82a04473bee5218/a0b58/Win_Quicktime_Step5_EN.webp 230w", "/en/static/a546aa064a676c50b82a04473bee5218/da4cb/Win_Quicktime_Step5_EN.webp 416w"],
              "sizes": "(max-width: 416px) 100vw, 416px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a546aa064a676c50b82a04473bee5218/e83b4/Win_Quicktime_Step5_EN.jpg 230w", "/en/static/a546aa064a676c50b82a04473bee5218/8d0ef/Win_Quicktime_Step5_EN.jpg 416w"],
              "sizes": "(max-width: 416px) 100vw, 416px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a546aa064a676c50b82a04473bee5218/8d0ef/Win_Quicktime_Step5_EN.jpg",
              "alt": "QuickTime Video Plugin",
              "title": "QuickTime Video Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select the Transport Protocol HTTP and Port ID 80.`}</p>
    <p><strong parentName="p">{`Step 8`}</strong>{`: Please confirm all settings with "OK". Next please open the IP camera in your web browser. The picture should now be shown.`}</p>
    <p><strong parentName="p">{`Note 1`}</strong>{`: You might have to start the Internet Explorer as Administrator. Just make a right click on the Internet Explorer Symbol in your Start menu and choose "Run as...".`}</p>
    <p><strong parentName="p">{`Note 2`}</strong>{`: If you use a Virus scanner such as AVAST, KASPERSKY, AVIRA, F-SECURE or AVG you have to create an exception for the IP address of the camera. The easiest way is to shortly turn of all Internet Security Software in order to check which software is blocking the ActiveX plugin from installation/execution.`}</p>
    <h3 {...{
      "id": "quicktime-setup-under-mac-osx",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#quicktime-setup-under-mac-osx",
        "aria-label": "quicktime setup under mac osx permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Quicktime Setup under Mac OSX`}</h3>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Please open your Finder and select Applications and Utilities as shown below.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d7cdfb176dca8b96febecacd16f4fca0/29d31/MacOS_Quicktime_Step1_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgABBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHrMJHZH//EABkQAQACAwAAAAAAAAAAAAAAAAEAEQIQQf/aAAgBAQABBQLElFhroT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAZEAADAQEBAAAAAAAAAAAAAAAAARFBIVH/2gAIAQEAAT8hS5ekpmCmuiS8IsYKh//aAAwDAQACAAMAAAAQ7P8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8QiP/EABsQAQADAQADAAAAAAAAAAAAAAEAESExQVGx/9oACAEBAAE/EEd02YpLkKtdex+hW66y4WMvyxv/AEYPN99s/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7cdfb176dca8b96febecacd16f4fca0/e4706/MacOS_Quicktime_Step1_EN.avif 230w", "/en/static/d7cdfb176dca8b96febecacd16f4fca0/d1af7/MacOS_Quicktime_Step1_EN.avif 460w", "/en/static/d7cdfb176dca8b96febecacd16f4fca0/70e80/MacOS_Quicktime_Step1_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d7cdfb176dca8b96febecacd16f4fca0/a0b58/MacOS_Quicktime_Step1_EN.webp 230w", "/en/static/d7cdfb176dca8b96febecacd16f4fca0/bc10c/MacOS_Quicktime_Step1_EN.webp 460w", "/en/static/d7cdfb176dca8b96febecacd16f4fca0/426ac/MacOS_Quicktime_Step1_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7cdfb176dca8b96febecacd16f4fca0/e83b4/MacOS_Quicktime_Step1_EN.jpg 230w", "/en/static/d7cdfb176dca8b96febecacd16f4fca0/e41a8/MacOS_Quicktime_Step1_EN.jpg 460w", "/en/static/d7cdfb176dca8b96febecacd16f4fca0/29d31/MacOS_Quicktime_Step1_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d7cdfb176dca8b96febecacd16f4fca0/29d31/MacOS_Quicktime_Step1_EN.jpg",
              "alt": "QuickTime Video Plugin",
              "title": "QuickTime Video Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the Utilities in your Applications folder.`}</p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: Inside the folder Utilities please open the program Terminal.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/62f33b935d44bcb1e130a030203b5176/29d31/MacOS_Quicktime_Step2_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHWuXYwD//EABcQAAMBAAAAAAAAAAAAAAAAAAABEUH/2gAIAQEAAQUCSpFRGw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGI/8QAGBAAAgMAAAAAAAAAAAAAAAAAAAEQITH/2gAIAQEABj8CHLs0/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAERITHR/9oACAEBAAE/IVw6RUYhJQJauC9GCpo//9oADAMBAAIAAwAAABAQ3//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/EKr/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/EA//xAAdEAACAQQDAAAAAAAAAAAAAAABEQAhMUHRUbHB/9oACAEBAAE/ECDglt1WiEKGCpvCAYOcnmFKiR2UI6kGoVPHU//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/62f33b935d44bcb1e130a030203b5176/e4706/MacOS_Quicktime_Step2_EN.avif 230w", "/en/static/62f33b935d44bcb1e130a030203b5176/d1af7/MacOS_Quicktime_Step2_EN.avif 460w", "/en/static/62f33b935d44bcb1e130a030203b5176/70e80/MacOS_Quicktime_Step2_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/62f33b935d44bcb1e130a030203b5176/a0b58/MacOS_Quicktime_Step2_EN.webp 230w", "/en/static/62f33b935d44bcb1e130a030203b5176/bc10c/MacOS_Quicktime_Step2_EN.webp 460w", "/en/static/62f33b935d44bcb1e130a030203b5176/426ac/MacOS_Quicktime_Step2_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/62f33b935d44bcb1e130a030203b5176/e83b4/MacOS_Quicktime_Step2_EN.jpg 230w", "/en/static/62f33b935d44bcb1e130a030203b5176/e41a8/MacOS_Quicktime_Step2_EN.jpg 460w", "/en/static/62f33b935d44bcb1e130a030203b5176/29d31/MacOS_Quicktime_Step2_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/62f33b935d44bcb1e130a030203b5176/29d31/MacOS_Quicktime_Step2_EN.jpg",
              "alt": "QuickTime Video Plugin",
              "title": "QuickTime Video Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Please start the Terminal from your Utilities folder.`}</p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: The Terminal window will open.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "586px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b840e6fd3d427ed5bbd4f5c833547001/f8a08/MacOS_Quicktime_Step3_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.695652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAG6AD//xAAXEAADAQAAAAAAAAAAAAAAAAAAARES/9oACAEBAAEFAsoiIj//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAVEAEBAAAAAAAAAAAAAAAAAAAAMf/aAAgBAQAGPwKIj//EABcQAQADAAAAAAAAAAAAAAAAAAABUWH/2gAIAQEAAT8hLImo/9oADAMBAAIAAwAAABBwD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAAICAwAAAAAAAAAAAAAAAAABIZExYXH/2gAIAQEAAT8Q1H0S8Ujbmg//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b840e6fd3d427ed5bbd4f5c833547001/e4706/MacOS_Quicktime_Step3_EN.avif 230w", "/en/static/b840e6fd3d427ed5bbd4f5c833547001/d1af7/MacOS_Quicktime_Step3_EN.avif 460w", "/en/static/b840e6fd3d427ed5bbd4f5c833547001/263c9/MacOS_Quicktime_Step3_EN.avif 586w"],
              "sizes": "(max-width: 586px) 100vw, 586px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b840e6fd3d427ed5bbd4f5c833547001/a0b58/MacOS_Quicktime_Step3_EN.webp 230w", "/en/static/b840e6fd3d427ed5bbd4f5c833547001/bc10c/MacOS_Quicktime_Step3_EN.webp 460w", "/en/static/b840e6fd3d427ed5bbd4f5c833547001/7fed0/MacOS_Quicktime_Step3_EN.webp 586w"],
              "sizes": "(max-width: 586px) 100vw, 586px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b840e6fd3d427ed5bbd4f5c833547001/e83b4/MacOS_Quicktime_Step3_EN.jpg 230w", "/en/static/b840e6fd3d427ed5bbd4f5c833547001/e41a8/MacOS_Quicktime_Step3_EN.jpg 460w", "/en/static/b840e6fd3d427ed5bbd4f5c833547001/f8a08/MacOS_Quicktime_Step3_EN.jpg 586w"],
              "sizes": "(max-width: 586px) 100vw, 586px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b840e6fd3d427ed5bbd4f5c833547001/f8a08/MacOS_Quicktime_Step3_EN.jpg",
              "alt": "QuickTime Video Plugin",
              "title": "QuickTime Video Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 4`}</strong>{`: Please input the following or copy the line into Terminal to make sure it is correct qtdefaults write TransportSettings "HTTP" "80".`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "587px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f52e89f4fa1529f3a49cbf97abbdc5d7/f89ba/MacOS_Quicktime_Step4_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.695652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAG6AD//xAAXEAADAQAAAAAAAAAAAAAAAAAAARIR/9oACAEBAAEFApRKMR//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAADAAAAAAAAAAAAAAAAAAAAEDH/2gAIAQEABj8CUP/EABcQAQEBAQAAAAAAAAAAAAAAAABhAVH/2gAIAQEAAT8hmi3kf//aAAwDAQACAAMAAAAQAA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAYEAEBAAMAAAAAAAAAAAAAAAABACExkf/aAAgBAQABPxCIWjLc8L//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f52e89f4fa1529f3a49cbf97abbdc5d7/e4706/MacOS_Quicktime_Step4_EN.avif 230w", "/en/static/f52e89f4fa1529f3a49cbf97abbdc5d7/d1af7/MacOS_Quicktime_Step4_EN.avif 460w", "/en/static/f52e89f4fa1529f3a49cbf97abbdc5d7/9a6c2/MacOS_Quicktime_Step4_EN.avif 587w"],
              "sizes": "(max-width: 587px) 100vw, 587px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f52e89f4fa1529f3a49cbf97abbdc5d7/a0b58/MacOS_Quicktime_Step4_EN.webp 230w", "/en/static/f52e89f4fa1529f3a49cbf97abbdc5d7/bc10c/MacOS_Quicktime_Step4_EN.webp 460w", "/en/static/f52e89f4fa1529f3a49cbf97abbdc5d7/cf155/MacOS_Quicktime_Step4_EN.webp 587w"],
              "sizes": "(max-width: 587px) 100vw, 587px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f52e89f4fa1529f3a49cbf97abbdc5d7/e83b4/MacOS_Quicktime_Step4_EN.jpg 230w", "/en/static/f52e89f4fa1529f3a49cbf97abbdc5d7/e41a8/MacOS_Quicktime_Step4_EN.jpg 460w", "/en/static/f52e89f4fa1529f3a49cbf97abbdc5d7/f89ba/MacOS_Quicktime_Step4_EN.jpg 587w"],
              "sizes": "(max-width: 587px) 100vw, 587px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f52e89f4fa1529f3a49cbf97abbdc5d7/f89ba/MacOS_Quicktime_Step4_EN.jpg",
              "alt": "QuickTime Video Plugin",
              "title": "QuickTime Video Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 5`}</strong>{`: Please confirm your input. The following message should be shown in your Terminal window.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "587px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6037c71206a76f897cfcd28ad49010a6/f89ba/MacOS_Quicktime_Step5_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.695652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB26FB/8QAFxAAAwEAAAAAAAAAAAAAAAAAAAESEf/aAAgBAQABBQKUSjEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAABAx/9oACAEBAAY/AlD/xAAYEAACAwAAAAAAAAAAAAAAAAAAARFBUf/aAAgBAQABPyFFFQ8h/9oADAMBAAIAAwAAABDwD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABcQAQEBAQAAAAAAAAAAAAAAAAEAMYH/2gAIAQEAAT8QXosYovU5f//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6037c71206a76f897cfcd28ad49010a6/e4706/MacOS_Quicktime_Step5_EN.avif 230w", "/en/static/6037c71206a76f897cfcd28ad49010a6/d1af7/MacOS_Quicktime_Step5_EN.avif 460w", "/en/static/6037c71206a76f897cfcd28ad49010a6/9a6c2/MacOS_Quicktime_Step5_EN.avif 587w"],
              "sizes": "(max-width: 587px) 100vw, 587px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6037c71206a76f897cfcd28ad49010a6/a0b58/MacOS_Quicktime_Step5_EN.webp 230w", "/en/static/6037c71206a76f897cfcd28ad49010a6/bc10c/MacOS_Quicktime_Step5_EN.webp 460w", "/en/static/6037c71206a76f897cfcd28ad49010a6/cf155/MacOS_Quicktime_Step5_EN.webp 587w"],
              "sizes": "(max-width: 587px) 100vw, 587px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6037c71206a76f897cfcd28ad49010a6/e83b4/MacOS_Quicktime_Step5_EN.jpg 230w", "/en/static/6037c71206a76f897cfcd28ad49010a6/e41a8/MacOS_Quicktime_Step5_EN.jpg 460w", "/en/static/6037c71206a76f897cfcd28ad49010a6/f89ba/MacOS_Quicktime_Step5_EN.jpg 587w"],
              "sizes": "(max-width: 587px) 100vw, 587px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6037c71206a76f897cfcd28ad49010a6/f89ba/MacOS_Quicktime_Step5_EN.jpg",
              "alt": "QuickTime Video Plugin",
              "title": "QuickTime Video Plugin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Continue the plugin installation.`}</p>
    <p><strong parentName="p">{`Step 6`}</strong>{`: Open the IP camera in your web browser. The video should now be shown.`}</p>
    <p><strong parentName="p">{`Note 1`}</strong>{`: You might have to start the Internet Explorer as Administrator. Just make a right click on the Internet Explorer Symbol in your Start menu and choose "Run as...".`}</p>
    <p><strong parentName="p">{`Note 2`}</strong>{`: If you use a Virus scanner such as AVAST, KASPERSKY, AVIRA, F-SECURE or AVG you have to create an exception for the IP address of the camera. The easiest way is to shortly turn of all Internet Security Software in order to check which software is blocking the ActiveX plugin from installation/execution.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      